import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { getInputErrorText } from '../InputErrorText';
import { useFormikDisableContext } from '../../context/formikDisable';

export interface SelectOption {
  value: number | string;
  label: string;
}

export interface SelectInputProps {
  id: string;
  label: string;
  required?: boolean;
  options: SelectOption[];
  withNoneOption?: boolean;
  multiple?: boolean;
}

export function SelectInput({
  id,
  label,
  required,
  options,
  withNoneOption,
  multiple,
}: SelectInputProps) {
  const { t } = useTranslation('common');
  // Idealy we would get the required flag from validationSchema
  // from formik context (useFormikContext) to have one source of thruth about
  // field requirements. However, there is a bug that validationSchema
  // is not included in formik context even tho the type definitions say otherwise.
  // see: https://github.com/formium/formik/pull/2521
  const [{ value, ...field }, meta] = useField(id);
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;

  // Fix for warning: A component is changing the uncontrolled value state of Select to be controlled.
  const fieldValue = value === null || value === undefined ? '' : value;

  return (
    <FormControl required={required} variant="outlined" fullWidth={true} margin="normal">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        {...field}
        value={fieldValue}
        labelId={id}
        label={label}
        error={!!errorMessage}
        disabled={isSubmitting || isDisabled}
        required={required}
        fullWidth={true}
        multiple={multiple}
      >
        {withNoneOption && (
          <MenuItem value={undefined}>
            <em>{t('none')}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={true}>{getInputErrorText(errorMessage)}</FormHelperText>
    </FormControl>
  );
}
