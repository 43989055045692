import { useNavigate } from 'react-router-dom';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { OrganizationCreateOrEditInput, OrganizationCreateResult } from '../../models';
import { OrganizationForm, OrganizationFormValues } from './OrganizationForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateOrganizationPage() {
  const { t } = useTranslation('organization');
  const navigate = useNavigate();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();

  async function handleSubmit(values: OrganizationFormValues) {
    const input: OrganizationCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<OrganizationCreateResult, OrganizationCreateOrEditInput>(
      'POST',
      ApiRoutes.Organizations,
      input,
    );
  }

  async function handleSubmitCompleted(data?: OrganizationCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/organizations/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('organization', { context: data?.id }), { context: data?.id });
  }

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="ORGANIZATIONS_READ">
            <OrganizationForm<OrganizationCreateResult>
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
