import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import React from 'react';
import { Alarm, AlarmType, AlarmTypeEnum, TriggerTime, UpdateAlarmInput } from '../../models';
import { ApiRoutes, useGetApi } from '../../services/api';
import { getTodayDateWithCertainHour } from '../../utils';
import { ProgressIndicator } from '../ProgressIndicator';
import { SubmitFormHandler } from '../Form';
import { AlarmForm, AlarmFormValues } from '../AlarmForm';
import { useEntityActionSnackbar } from '../../hooks';
import { useAlarmActiveShowHideForm } from '../../context/alarmActiveShowHideForm';

export function getTriggerTimesFromAlarmFormValues(values: AlarmFormValues): TriggerTime[] {
  const triggerTimes: TriggerTime[] = [];
  if (values.triggerTimes.morning) {
    triggerTimes.push({
      morning: true,
      afternoon: false,
      evening: false,
      times: values.triggerTimes.morningTimes,
    });
  }
  if (values.triggerTimes.afternoon) {
    triggerTimes.push({
      morning: false,
      afternoon: true,
      evening: false,
      times: values.triggerTimes.afternoonTimes,
    });
  }
  if (values.triggerTimes.evening) {
    triggerTimes.push({
      morning: false,
      afternoon: false,
      evening: true,
      times: values.triggerTimes.eveningTimes,
    });
  }

  return triggerTimes;
}

interface Props {
  onSubmit: SubmitFormHandler<UpdateAlarmInput>;
  alarm: Alarm;
}

export function AlarmComponent({ onSubmit, alarm }: Props) {
  const navigate = useNavigate();
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('alarm');
  const { hideAddNew } = useAlarmActiveShowHideForm();

  const [{ data: alarmTypes, isLoading: areAlarmTypesLoading }] = useGetApi<AlarmType[]>(
    ApiRoutes.AlarmTypes,
  );
  const [{ data: autocompleteDrugNames, isLoading: areAutocompleteDrugNamesLoading }] = useGetApi<
    string[]
  >(ApiRoutes.AutocompleteDrugNames);

  const reminderId = alarmTypes?.find((alarmType) => alarmType.type === AlarmTypeEnum.REMINDER)?.id;

  function handleSubmit(values: AlarmFormValues) {
    const triggerTimes: TriggerTime[] = getTriggerTimesFromAlarmFormValues(values);

    return onSubmit({
      ...values,
      typeIds: values.typeIds,
      startOnDatetime: values.startOnDatetime || new Date(),
      endOnDatetime: values.endOnDatetime,
      repetitionInterval: values.repetitionIntervalIsActive ? values.repetitionInterval : null,
      repetitionDays: !values.repetitionIntervalIsActive ? values.repetitionDays : null,
      triggerTimes: triggerTimes,
      drugName: reminderId != null && values.typeIds.includes(reminderId) ? values.drugName : null,
    });
  }

  function handleSubmitCompleted() {
    hideAddNew();
    // go back two steps - first one is edit subpage, second one is alarm group
    navigate(-2);
    enqueueEntityUpdatedSnackbar(t('alarm'));
  }

  if (areAlarmTypesLoading || areAutocompleteDrugNamesLoading) {
    return <ProgressIndicator />;
  }

  if (!alarm) {
    return <div>{t('error.alarmNotFound')}</div>;
  }

  if (!alarmTypes) {
    return <div>{t('error.alarmTypesNotFound')}</div>;
  }

  const morningTimes = alarm.triggerTimes.filter((x) => x.morning);
  const afternoonTimes = alarm.triggerTimes.filter((x) => x.afternoon);
  const eveningTimes = alarm.triggerTimes.filter((x) => x.evening);

  return (
    <React.Fragment>
      <AlarmForm
        alarmTypes={alarmTypes}
        initialValues={{
          typeIds: alarm.typeIds,
          drugName: alarm.drugName,
          triggerTimes: {
            morning: morningTimes && morningTimes.length > 0,
            morningTimes:
              morningTimes.length > 0 && morningTimes[0].times.length > 0
                ? morningTimes[0].times
                : [getTodayDateWithCertainHour(8) as Date],
            afternoon: afternoonTimes && afternoonTimes.length > 0,
            afternoonTimes:
              afternoonTimes.length > 0 && afternoonTimes[0].times.length > 0
                ? afternoonTimes[0].times
                : [getTodayDateWithCertainHour(12) as Date],
            evening: eveningTimes && eveningTimes.length > 0,
            eveningTimes:
              eveningTimes.length > 0 && eveningTimes[0].times.length > 0
                ? eveningTimes[0].times
                : [getTodayDateWithCertainHour(16) as Date],
          },
          repetitionDays: alarm.repetitionDays ?? [],
          repetitionIntervalIsActive: alarm.repetitionInterval != null,
          repetitionInterval: alarm.repetitionInterval ?? 1,
          startOnDatetime: alarm.startOnDatetime,
          endOnDatetime: alarm.endOnDatetime,
        }}
        onSubmit={handleSubmit}
        onSubmitCompleted={handleSubmitCompleted}
        autocompleteDrugNames={autocompleteDrugNames || []}
      />
    </React.Fragment>
  );
}
