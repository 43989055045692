import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box, Divider, Typography } from '@mui/material';
import { Department } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { DepartmentInput } from '../../components/DepartmentsInput';
import { useRequireFunctionality } from '../../hooks';

export interface DeviceFormValues {
  id: string;
  chassisId: string;
  note?: string | null;
  departmentIds: string[];
}

interface Props<TResponseData = never> {
  initialValues?: DeviceFormValues;
  onSubmit: SubmitFormHandler<DeviceFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  departments: Department[];
}

export function DeviceForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  departments,
}: Props<TResponseData>) {
  const { t } = useTranslation('device');
  const { hasFunctionality: hasOrganizationFunctionality } =
    useRequireFunctionality('ORGANIZATIONS_READ');
  const defaultFormValues: DeviceFormValues = {
    id: '',
    chassisId: '',
    note: '',
    departmentIds: [],
  };

  const classes = useStyles();

  const schema: Yup.SchemaOf<DeviceFormValues> = Yup.object({
    id: Yup.string().required(t('id.validation.required')),
    chassisId: Yup.string().required(t('chassisId.validation.required')),
    note: Yup.string().nullable().optional(),
    departmentIds: Yup.array()
      .min(1)
      .required(t('department.validation.required'))
      .of(Yup.string().required(t('department.validation.required'))),
  });

  return (
    <EntityForm<DeviceFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="id" label={t('id.label')} required={true} type="text" disabled={true} />
      </Box>
      <Box className={classes.formRow}>
        <TextInput
          id="chassisId"
          label={t('chassisId.label')}
          required={true}
          type="text"
          disabled={true}
        />
      </Box>
      {hasOrganizationFunctionality && (
        <Box className={classes.formRow}>
          <TextInput
            multiline={true}
            rows={5}
            rowsMax={20}
            id="note"
            label={t('note.label')}
            required={false}
            type="text"
          />
        </Box>
      )}
      {!hasOrganizationFunctionality && (
        <Box>
          <Divider classes={{ root: classes.dividerMargin }} />
          <Typography variant="h6" component={'div'} className={classes.bold}>
            {t('departments.title')}
          </Typography>
          <DepartmentInput id="departmentIds" departments={departments} required={true} />
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
