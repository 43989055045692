import { useNavigate, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { Group, GroupCreateOrEditInput, GroupCreateResult } from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React from 'react';
import { useEntityActionSnackbar } from '../../hooks';
import { GroupFormValues, GroupForm } from './GroupForm';
import { GroupHeader } from '../../components/GroupHeader';

export function GroupPage() {
  const { t } = useTranslation('group');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [
    { data: group, isLoading: isGroupLoading },
    { refetch: refetchDepartment },
    { lastFetchDatetime },
  ] = useGetApi<Group>(ApiRoutes.Group(id as string));

  async function onSubmit(values: GroupFormValues) {
    const input: GroupCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<never, GroupCreateOrEditInput>(
      'PUT',
      ApiRoutes.Group(id as string),
      input,
    );
  }

  async function onSubmitCompleted(data?: GroupCreateResult) {
    await refetchDepartment();
    navigate(`/groups/${id}`);

    enqueueEntityUpdatedSnackbar(t('group', { context: data?.name }), {
      context: data?.name,
    });
  }

  if (isGroupLoading) {
    return <ProgressIndicator />;
  }

  if (group == null) {
    return <div>{t('error.departmentNotFound')}</div>;
  }

  const { ...groupFields } = group;

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <StickyTopAppBar>
        <GroupHeader group={group} lastUpdated={lastFetchDatetime} />
      </StickyTopAppBar>
      <Page noPaper={true} requireFunctionality="DEPARTMENTS_READ">
        <GroupForm
          initialValues={{
            ...groupFields,
          }}
          onSubmit={onSubmit}
          onSubmitCompleted={onSubmitCompleted}
        />
      </Page>
    </FunctionalityProvider>
  );
}
