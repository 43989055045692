import { GridCellParams, GridRowId } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { EDIT_SUBPATH } from '../../../constants';
import { Device, Organization } from '../../../models';
import { DepartmentCellRenderer, DeviceStateCellRenderer } from '../Renderer';
import { useRequireFunctionality } from '../../../hooks';
import { SearchOrganizationComponent } from '../../SearchOrganizationComponent';

interface Props {
  data: Device[];
  organizations: Organization[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Device>;
  onRowClick?: RowClickHandler<Device>;
  onAddOrganizationHandle: (deviceIds: string[], organizationId: string) => Promise<void>;
}

export function DevicesGrid({
  data,
  organizations,
  onDelete,
  onDeleteCompleted,
  onRowClick,
  onAddOrganizationHandle,
}: Props) {
  const { t } = useTranslation('device');
  const { hasFunctionality: hasOrganizationFunctionality } =
    useRequireFunctionality('ORGANIZATIONS_READ');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const columns: GridColumnDefinition<Device>[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
      flex: 2,
      renderCell: (params: GridCellParams) => <strong>{params.id}</strong>,
    },
    {
      field: 'chassisId',
      headerName: t('chassisId.label'),
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'departments',
      headerName: t('departments.label'),
      flex: 2,
      minWidth: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: DepartmentCellRenderer,
    },
    {
      field: 'latestOnline',
      headerName: t('latestOnline.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'state',
      headerName: t('state.label'),
      minWidth: 250,
      flex: 2,
      renderCell: DeviceStateCellRenderer,
    },
  ];

  const organizationGridColumn: GridColumnDefinition<Device> = {
    field: 'organizationName',
    headerName: t('organization.label'),
    minWidth: 100,
    flex: 2,
  };

  if (hasOrganizationFunctionality) {
    columns.push(organizationGridColumn);
  }

  function getEditPath(id: number) {
    return `/devices/${id}/${EDIT_SUBPATH}`;
  }

  function onMultiSelectionRow(rows: GridRowId[]): void {
    setSelectedIds(rows.map((id) => id as string));
  }

  return (
    <>
      <Grid
        columns={columns}
        rows={data}
        getEditPath={getEditPath}
        onDelete={hasOrganizationFunctionality === false ? onDelete : undefined}
        onDeleteCompleted={hasOrganizationFunctionality === false ? onDeleteCompleted : undefined}
        onRowClick={hasOrganizationFunctionality === false ? onRowClick : undefined}
        onMultiSelectionRow={hasOrganizationFunctionality ? onMultiSelectionRow : undefined}
        selectedIds={hasOrganizationFunctionality ? selectedIds : undefined}
        disablePagination={false}
      />
      {organizations && selectedIds.length > 0 && (
        <SearchOrganizationComponent
          deviceIds={selectedIds}
          organizations={organizations}
          onAddOrganizationHandle={onAddOrganizationHandle}
        />
      )}
    </>
  );
}
