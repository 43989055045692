import { useNavigate, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import {
  Organization,
  OrganizationCreateOrEditInput,
  OrganizationCreateResult,
} from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React from 'react';
import { useEntityActionSnackbar } from '../../hooks';
import { OrganizationFormValues } from './OrganizationForm';
import { OrganizationNavigation } from './OrganizationNavigation';
import { OrganizationHeader } from '../../components/OrganizationHeader';

export function OrganizationPage() {
  const { t } = useTranslation('organization');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [
    { data: organization, isLoading: isOrganizationLoading },
    { refetch: refetchOrganization },
    { lastFetchDatetime },
  ] = useGetApi<Organization>(ApiRoutes.Organization(id as string));

  async function handleSubmit(values: OrganizationFormValues) {
    const input: OrganizationCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<never, OrganizationCreateOrEditInput>(
      'PUT',
      ApiRoutes.Organization(id as string),
      input,
    );
  }

  async function handleSubmitCompleted(data?: OrganizationCreateResult) {
    await refetchOrganization();
    navigate(`/organizations/${id}`);

    enqueueEntityUpdatedSnackbar(t('organization', { context: data?.name }), {
      context: data?.name,
    });
  }

  if (isOrganizationLoading) {
    return <ProgressIndicator />;
  }

  if (organization == null) {
    return <div>{t('error.organizationNotFound')}</div>;
  }

  const { ...organizationFields } = organization;

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <StickyTopAppBar>
        <OrganizationHeader organization={organization} lastUpdated={lastFetchDatetime} />
      </StickyTopAppBar>
      <Page noPaper={true} requireFunctionality="ORGANIZATIONS_READ">
        <OrganizationNavigation
          id={id as string}
          initialValues={{
            ...organizationFields,
          }}
          onSubmit={handleSubmit}
          onSubmitCompleted={handleSubmitCompleted}
        />
      </Page>
    </FunctionalityProvider>
  );
}
