import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Organization } from '../../../models';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: Organization[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Organization>;
  onRowClick?: RowClickHandler<Organization>;
}

export function OrganizationsGrid({ data, onDelete, onDeleteCompleted, onRowClick }: Props) {
  const { t } = useTranslation('organization');
  const columns: GridColumnDefinition<Organization>[] = [
    {
      field: 'name',
      headerName: t('name.label'),
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'ico',
      headerName: t('ico.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'deviceCount',
      headerName: t('deviceCount.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'medicAuthentication',
      headerName: t('medicAuthentication.label'),
      type: 'boolean',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? <strong>Yes</strong> : <strong>No</strong>,
    },
  ];

  function getEditPath(id: number) {
    return `/organizations/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
